







































































































@import "../../assets/css/statistics";

.analysis {
	width: 65%;
}

.rightCount {
	width: calc(100% - 62% - 64px);
}
